.dropdown:disabled {
  color: #5b6b7b;
}

.focusedLabel {
  color: #bec3cc !important;
}

.selectIcon {
  color: #ffffff !important;
}
