.btnControlContainer {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;

  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border-radius: 12px;
}

.btnControlContainer.disabled,
.btnControlContainer.disabled:hover,
.btnControlContainer.disabled:active {
  background-color: #5b6b7b !important;
  opacity: 0.5;
  border: none;

  pointer-events: none;
}

.btnControlContainer.blue.normal {
  border: none;
  background-color: #1774cc;
}
.btnControlContainer.gray.normal {
  border: none;
  background-color: #313a42;
}
.btnControlContainer.red.normal {
  border: none;
  background-color: #d93b3d;
}
.btnControlContainer.white.normal {
  border: none;
  background-color: #ffffff;
}

.btnControlContainer.blue.hover {
  border: 3px solid #46aff2;
  background-color: #1774cc;
}
.btnControlContainer.gray.hover {
  border: 3px solid #5b6b7b;
  background-color: #313a42;
}
.btnControlContainer.red.hover {
  border: 3px solid #ffffff;
  background-color: #d93b3d;
}
.btnControlContainer.white.hover {
  border: 3px solid #46aff2;
  background-color: #ffffff;
}

.btnControlContainer.blue.active {
  border: none;
  background-color: #46aff2;
}
.btnControlContainer.gray.active {
  border: none;
  background-color: #5b6b7b;
}
.btnControlContainer.red.active {
  border: none;
  background-color: #ff787a;
}
.btnControlContainer.white.active {
  border: none;
  background-color: #46aff2;
}
