.patientControl {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;

  background-color: #232a30;

  border-radius: 4px;
  display: flex;
  gap: 20px;

  justify-content: center;
  align-items: center;

  padding: 10px 16px;
}

.controlCameraType {
  font-size: 12px;
  line-height: 16px;
  font-family: 'Open Sans';
  letter-spacing: 0.15px;
  color: #bec3cc;
  align-self: flex-start;
  padding: 0px 16px 16px 16px;
}

.controlContainer {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #313a42;
  width: 68px;
  height: 68px;
  border-radius: 80px;

  overflow: hidden;
}

.homeContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 38px;
  height: 38px;
  border-radius: 38px;
  background-color: #313a42;
  border: 4px solid #1a1f24;

  z-index: 1;
}

.homeContainer:hover {
  background-color: #5b6b7b;
  cursor: pointer;
}
.homeContainer:active {
  background-color: #1774cc;
}

.divider {
  width: 94%;
  border: 1px solid #313a42;
  box-sizing: border-box;
}

.rangeContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 8px;

  width: 100%;

  box-sizing: border-box;
}

.inputRange {
  width: 174px;
}

.inputRange:active::-moz-range-thumb,
.inputRange:active::-webkit-slider-thumb,
.inputRange:active::-ms-thumb {
  opacity: 1;
  color: #46aff2 !important;
  border: 2px solid #232a30;
}

.forwardArrow {
  width: 12px;
  height: 12px;
}

.rangeButton {
  width: 20px;
  height: 20px;
}

.forwardArrowButton {
  width: 34px;
  height: 34px;

  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
}

.forwardArrowButton:hover {
  background-color: #5b6b7b;

  cursor: pointer;
}

.forwardArrowButton:active {
  background-color: #1774cc;
}

.cameraRightButton {
  background: #313a42;

  transform: rotate(45deg);

  right: -8.5px;
  top: auto;
}

.cameraUpButton {
  transform: rotate(45deg);

  position: absolute;
  top: -8.5px;
}

.cameraDownButton {
  transform: rotate(45deg);

  position: absolute;
  bottom: -8.5px;
}

.cameraLeftButton {
  transform: rotate(45deg);
  left: -8.5px;
}
