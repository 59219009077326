.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #000000;
  min-height: 100vh;
  height: var(--app-height);
}

.title {
  font-size: 16px;
  line-height: 20px;
  font-family: 'Open Sans';
  color: #ffffff;
  margin-top: 18px;
  max-width: 471px;
  text-align: center;
  letter-spacing: 0.5px;
  font-weight: 400;
  font-style: normal;
}
