.ccWrapper {
  width: 100%;
  max-width: 728px;
  height: 176px;
  max-height: 176px;

  overflow-y: hidden;

  font-family: 'Open Sans';
  font-style: normal;
}

.cc {
  margin: 30px 21px;
}

.messageWrapper {
  margin: 30px 0;
  max-width: 680px;
}

.messageSenderWrapper {
  display: flex;
  align-items: flex-start;
  gap: 15px;
}

.avatarWrapper {
  width: 32px;
  height: 32px;

  border-radius: 50%;

  background-color: #ad9fff;
  color: #ffff;

  display: flex;
  align-items: center;
  justify-content: center;
}

.avatarWrapper > img {
  width: 100%;
  height: 100%;

  border-radius: 50%;
}

.senderName {
  color: #bec3cc;

  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.message {
  margin-left: 48px;

  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;

  color: #ffff;
}
