.container {
  min-height: 100vh;
  height: 100vh;
  height: var(--app-height);
  min-height: var(--app-height);
  max-height: 100%;
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #1a1f24;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
}

.box {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.hospitalLogoWrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  max-height: 40px;
  width: 100%;
}

.hospitalLogo {
  height: 40px;
  object-fit: contain;
  max-width: 100%;
}

.content {
  padding: 80px 0;
  display: flex;
  flex-direction: row;
  column-gap: 39px;
}

.camera {
  width: 558.22px;
  height: 314px;
  background-color: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.camera video {
  transform: rotateY(180deg);
}

video {
  position: absolute;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  width: inherit;
  background-size: cover;
  overflow: hidden;
}

.cameraText {
  font-size: 24px;
  color: #ffffff;
}

.nameAndControls {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.title {
  color: #ffffff;
  font-size: 24px;
  line-height: 32px;
  max-width: 474px;
}

.inputName {
  width: 348px;
}

.controls {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.controlContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.controlText {
  font-size: 24px;
  color: #ffffff;
  padding-left: 16px;
}

.joinMeeting {
  border: 0;

  padding: 16px 64.5px;
  border-radius: 64px;
  font-size: 24px;
  color: #bec3cc;
  background-color: #232a30;
}

.joinMeetingFilled {
  background-color: #1774cc;
  color: #ffffff;
}

.joinMeetingFilled:active {
  opacity: 0.9;
}

.emptyBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.emptyTitle {
  color: #ffffff;
  font-size: 32px;
  line-height: 42.56px;
  padding-bottom: 16px;
}

.textFieldErrorMessage {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #ff787a;
  margin-top: '2px';
}
/*
 * Reference Tablets:
 * iPad air width: 820px
 * iPad width: 768px
 * Surface Pro 7 width: 912px
 * Galaxy Tab S4 width: 712px
 *
 * Reference Phones:
 * iPhone 12 Pro width: 390px
 * Pixel 5 width: 393px
 * Galaxy A51/71 width: 412px
 *
 * */
@media screen and (max-width: 920px) {
  .content {
    flex-direction: column;
    align-items: center;
    padding: 40px 0 72px 0;
  }

  .stanfordLogo {
    margin-top: 32px;
    width: 264px;
  }

  .camera {
    width: 300px;
    height: 168.75px;
  }

  .title {
    width: 278px;
    text-align: center;
    font-size: 20px;
    line-height: 24px;
    margin-top: 40px;
  }

  .inputName {
    margin-top: 40px;
    width: 278px;
  }

  .nameAndControls {
    justify-content: center;
  }

  .controls {
    margin-top: 40px;
  }

  .joinMeeting {
    font-size: 24px;
    margin-bottom: 40px;
  }

  /* Account for devices with short screens, to avoid
   * 'scrolling' on the Participant lobby.
   * iPhone SE height: 667px
   * Galaxy S5 height: 640px
   * Moto G4 height: 640px
   */
  @media (max-height: 675px) {
    .title {
      margin-top: 20px;
    }

    .inputName {
      margin-top: 20px;
    }

    .controls {
      margin-top: 20px;
    }

    .joinMeeting {
      font-size: 24px;
      margin-bottom: 20px;
    }
  }
}

@media (max-height: 800px) {
  .title {
    font-size: 17px;
  }

  .hospitalLogoWrapper {
    padding-top: 15px;
  }

  .content {
    padding: 15px 0 30px 0;
  }
}
