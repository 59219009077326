.container {
  display: none;
}

@media screen and (max-aspect-ratio: 16/9) {
  .container {
    height: 100%;
    width: 100%;
    display: block;
  }
}

.landscapeMode {
  background-color: #000000;
  width: 100%;
  align-items: center;
  justify-content: center;
  display: none;
}

.landscapeModeContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 328px;
  color: #ffffff;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  text-align: center;
  gap: 18px;
}

@media (max-width: 920px) and (min-aspect-ratio: 16/9) {
  .landscapeMode {
    height: 100vh;
    height: var(--app-height);
    display: flex;
  }
}
