.container {
  min-height: 100vh;
  height: 100vh;
  max-height: 100%;
  height: var(--app-height);
  max-height: var(--app-height);
  position: relative;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #1a1f24;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.loading {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #ffffff;
  /* padding-left: 16px; */
  text-align: center;
  margin-top: 25px;
}
