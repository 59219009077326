.content {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 34px;
  height: 16px;
  border-radius: 2px;
  outline: 2px solid #5b6b7b;
  cursor: pointer;
  font-size: 12px;
  font-weight: 400;
}

.content:hover {
  outline-color: #bec3cc;
  outline: 2px solid #bec3cc;
}
.content:active {
  outline-color: #ffffff;
  outline: 2px solid #ffffff;
}

.content_active {
  outline-color: #ffffff;
  outline: 2px solid #ffffff;
}

.coloredLogo {
    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 2px;
}

.none {
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.15px;
  font-weight: 400;
  font-family: 'Open Sans';
  padding-left: 4px;
  padding-right: 4px;
  background-color: #5b6b7b;
  border-radius: 2px;
  color: #bec3cc;
}

.none:hover {
  color: #bec3cc;
  outline: 2px solid #bec3cc;
}
.none:active {
  color: #ffffff;
  outline: 2px solid #ffffff;
}

.none_active {
  color: #ffffff;
  outline: 2px solid #ffffff;
}

.dark_label {
    color: #000000;
}

.light_label {
    color: #ffffff;
}
