.messageActionBtn {
  padding: 4px 12px;
  border: 0px;
  border-radius: 24px;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.5px;
  font-weight: 400;
  font-family: 'Open Sans';
}
