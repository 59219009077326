.container {
  display: flex;
  max-height: 100vh;
  background-color: #232a30;
  border-top: 1px solid #000000;
  border-right: 1px solid #000000;
  border-left: 1px solid #000000;
}

.contentLeft {
  padding: 8px 8px 16px 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contentLeftTop {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contentLeftBottom {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.contentLeftTopLogo {
  padding-bottom: 24px;
}

.contentRight {
  width: 240px;
  flex-direction: column;
  border-left: 1px solid #000000;;
  max-height: 100vh;
  overflow: auto;
}
