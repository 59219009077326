.container {
  display: flex;
  width: 100%;
  box-sizing: border-box;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px 16px 24px;
}

.titleContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.title {
  color: #ffffff;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
}
