@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.spinner {
  animation: rotate 1.3s linear infinite;
  background: #1774cc;
  border-radius: 50%;
  height: 32px;
  width: 32px;
  position: relative;
}

.spinner::before,
.spinner::after {
  content: '';
  position: absolute;
}

.spinner::before {
  border-radius: 50%;
  background: linear-gradient(0deg, hsla(0, 0%, 0%, 1) 50%, hsla(0, 0%, 10%, 0.9) 100%) 0% 0%,
    linear-gradient(90deg, hsla(0, 0%, 10%, 0.9) 0%, hsla(0, 0%, 30%, 0.6) 100%) 100% 0%,
    linear-gradient(180deg, hsla(0, 0%, 30%, 0.6) 0%, hsla(0, 0%, 60%, 0.3) 100%) 100% 100%,
    linear-gradient(360deg, hsla(0, 0%, 60%, 0.3) 0%, hsla(0, 0%, 100%, 0) 100%) 0% 100%;
  background-repeat: no-repeat;
  background-size: 50% 50%;
  top: -1px;
  bottom: -1px;
  left: -1px;
  right: -1px;
}

.spinner::after {
  background: #000000;
  border-radius: 50%;
  top: 6%;
  bottom: 6%;
  left: 6%;
  right: 6%;
}

.spinner2 {
  animation: rotate 1.3s linear infinite;
  background: #1774cc;
  border-radius: 50%;
  height: 32px;
  width: 32px;
  position: relative;
}

.spinner2::before,
.spinner2::after {
  content: '';
  position: absolute;
}

.spinner2::before {
  border-radius: 50%;
  background: linear-gradient(0deg, hsla(208, 15%, 23%, 1) 50%, hsla(208, 15%, 23%, 0.9) 100%) 0% 0%,
    linear-gradient(90deg, hsla(208, 15%, 23%, 0.9) 0%, hsla(0, 0%, 30%, 0.6) 100%) 100% 0%,
    linear-gradient(180deg, hsla(0, 0%, 30%, 0.6) 0%, hsla(0, 0%, 60%, 0.3) 100%) 100% 100%,
    linear-gradient(360deg, hsla(0, 0%, 60%, 0.3) 0%, hsla(0, 0%, 100%, 0) 100%) 0% 100%;
  background-repeat: no-repeat;
  background-size: 50% 50%;
  top: -1px;
  bottom: -1px;
  left: -1px;
  right: -1px;
}

.spinner2::after {
  background: #313a42;
  border-radius: 50%;
  top: 6%;
  bottom: 6%;
  left: 6%;
  right: 6%;
}

.spinner3 {
  animation: rotate 1.3s linear infinite;
  background: #1774cc;
  border-radius: 50%;
  height: 32px;
  width: 32px;
  position: relative;
}

.spinner3::before,
.spinner3::after {
  content: '';
  position: absolute;
}

.spinner3::before {
  border-radius: 50%;
  background: linear-gradient(0deg, hsla(208, 15%, 23%, 1) 50%, hsla(208, 15%, 23%, 0.9) 100%) 0% 0%,
    linear-gradient(90deg, hsla(208, 15%, 23%, 0.9) 0%, hsla(0, 0%, 30%, 0.6) 100%) 100% 0%,
    linear-gradient(180deg, hsla(0, 0%, 30%, 0.6) 0%, hsla(0, 0%, 60%, 0.3) 100%) 100% 100%,
    linear-gradient(360deg, hsla(0, 0%, 60%, 0.3) 0%, hsla(0, 0%, 100%, 0) 100%) 0% 100%;
  background-repeat: no-repeat;
  background-size: 50% 50%;
  top: -1px;
  bottom: -1px;
  left: -1px;
  right: -1px;
}

.spinner3::after {
  background: #1a1f24;
  border-radius: 50%;
  top: 6%;
  bottom: 6%;
  left: 6%;
  right: 6%;
}

.spinner4 {
  animation: rotate 1.3s linear infinite;
  background: #5892fe;
  border-radius: 50%;
  height: 32px;
  width: 32px;
  position: relative;
}

.spinner4::before,
.spinner4::after {
  content: '';
  position: absolute;
}

.spinner4::before {
  border-radius: 50%;
  background: linear-gradient(0deg, hsla(208, 15%, 23%, 1) 50%, hsla(208, 15%, 23%, 0.9) 100%) 0% 0%,
    linear-gradient(90deg, hsla(208, 15%, 23%, 0.9) 0%, hsla(0, 0%, 30%, 0.6) 100%) 100% 0%,
    linear-gradient(180deg, hsla(0, 0%, 30%, 0.6) 0%, hsla(0, 0%, 60%, 0.3) 100%) 100% 100%,
    linear-gradient(360deg, hsla(0, 0%, 60%, 0.3) 0%, hsla(0, 0%, 100%, 0) 100%) 0% 100%;
  background-repeat: no-repeat;
  background-size: 50% 50%;
  top: -1px;
  bottom: -1px;
  left: -1px;
  right: -1px;
}

.spinner4::after {
  background: #232a30;
  border-radius: 50%;
  top: 6%;
  bottom: 6%;
  left: 6%;
  right: 6%;
}

.spinnerWhite {
  animation: rotate 1.3s linear infinite;
  background: #1774cc;
  border-radius: 50%;
  height: 32px;
  width: 32px;
  position: relative;
}

.spinnerWhite::before,
.spinnerWhite::after {
  content: '';
  position: absolute;
}

.spinnerWhite::before {
  border-radius: 50%;
  background: linear-gradient(0deg, hsla(208, 15%, 23%, 1) 50%, hsla(208, 15%, 23%, 0.9) 100%) 0% 0%,
    linear-gradient(90deg, hsla(208, 15%, 23%, 0.9) 0%, hsla(0, 0%, 30%, 0.6) 100%) 100% 0%,
    linear-gradient(180deg, hsla(0, 0%, 30%, 0.6) 0%, hsla(0, 0%, 60%, 0.3) 100%) 100% 100%,
    linear-gradient(360deg, hsla(0, 0%, 60%, 0.3) 0%, hsla(0, 0%, 100%, 0) 100%) 0% 100%;
  background-repeat: no-repeat;
  background-size: 50% 50%;
  top: -1px;
  bottom: -1px;
  left: -1px;
  right: -1px;
}

.spinnerWhite::after {
  background: #ffffff;
  border-radius: 50%;
  top: 6%;
  bottom: 6%;
  left: 6%;
  right: 6%;
}
